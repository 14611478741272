import React, {FC} from 'react';
import {CompanyCard, ListFooter, FetchContainer} from 'components';
import {useFilter, useInfinite} from 'hooks';
import map from 'lodash/map';
import type {CompanyListItem} from 'types/company';

interface props {
  onShowLogin: () => void;
}

const CompaniesList: FC<props> = ({onShowLogin}) => {
  const {filters} = useFilter();
  const fetchCompanies = useInfinite({
    name: 'companies',
    url: 'companies/paginate',
    enabled: true
  });

  return (
    <FetchContainer
      isLoading={fetchCompanies?.isFetching && !fetchCompanies?.isFetchingNextPage && !fetchCompanies?.data}
      isError={fetchCompanies?.isError}
      onReFetch={fetchCompanies?.refetch}>
      <div className="fade-in">
        {map(fetchCompanies?.data, (company: CompanyListItem) => (
          <CompanyCard data={company} key={company?.id} showLogin={onShowLogin} />
        ))}
        <ListFooter
          onFetchMoreClick={() => {
            fetchCompanies?.fetchPage(undefined, filters, undefined, true);
          }}
          canFetchMore={fetchCompanies?.hasNextPage}
          isFetchingMore={fetchCompanies?.isFetchingNextPage}
        />
      </div>
    </FetchContainer>
  );
};

export default CompaniesList;
