import React, {FC, useCallback, useMemo, MouseEvent} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spinner} from 'components';
import {getDateFromIsoString} from 'utils';
import {usePost, useUser} from 'hooks';
import {defaultCompanyImg} from 'assets';
import type {CompanyListItem} from 'types/company';

interface Props {
  data?: CompanyListItem;
  showLogin: () => void;
}

const CompanyCard: FC<Props> = ({data, showLogin}) => {
  const user = useUser();

  const postSaveItem = usePost({
    url: 'saved_items'
  });

  const isSaved = useMemo(
    () => (postSaveItem?.isSuccess ? postSaveItem?.variables?.body?.action === 'store' : data?.saved),
    [data?.saved, postSaveItem]
  );

  const onStoreToList = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (!user?.isLogin && showLogin) showLogin();
      else
        postSaveItem.post({
          action: isSaved ? 'remove' : 'store',
          reference_type: 'company',
          reference_id: data?.id || 0
        });
    },
    [isSaved]
  );

  return (
    <div className="my-2 flex">
      <Link to={`/${data?.link}`} className="w-2/12 flex justify-start items-start lg:pl-4 cursor-pointer">
        <span
          className="rounded-full border border-gray-600 w-12 h-12 object-cover sm:w-16 sm:h-16 lg:w-20 lg:h-20"
          style={{
            backgroundImage: `url(${data?.avatar || defaultCompanyImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        />
      </Link>
      <div className="w-10/12 md:ml-0 pb-4 border-b border-gray-400 flex-1 flex  overflow-hidden ">
        <Link to={`/${data?.link}`} className="flex-7 cursor-pointer w-8/12">
          <p className="text-secondary line-clamp-1 md:text-lg font-semibold text-left" dir="auto">
            {data?.name}
          </p>
          <p dir="auto" className="text-sm text-gray-600 mt-2 line-clamp-1 text-left">
            {data?.tagline}
          </p>
          <p className="text-xs text-green-600">{`${data?.product_count} Products/Services`}</p>
        </Link>
        <div className="flex-3 flex flex-col w-4/12 items-end justify-center">
          <p className="text-xs text-gray-500 pb-1 mr-4">{getDateFromIsoString(data?.created_at)}</p>
          {postSaveItem.isLoading ? (
            <Spinner className="mr-6" />
          ) : (
            <Button className="w-[90%] sm:w-[60%]" success fill={isSaved} small onClick={onStoreToList}>
              {isSaved ? 'Listed' : 'Go to List'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
