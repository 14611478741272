import React, {ElementRef, FC, useCallback, useRef} from 'react';
import {Link} from 'react-router-dom';
import {useInfinite} from 'hooks';
import {ListFooter, DeletePrompt, FetchContainer} from 'components';
import {MdAdd} from 'react-icons/md';
import {EditableCompanyCard} from 'containers';
import type {CompanyDataApi} from 'types/company';
import map from 'lodash/map';

interface props {
  data?: CompanyDataApi;
  isAdminView: boolean;
}

const EditableProductsTab: FC<props> = ({data: companyData, isAdminView}) => {
  const deleteModalRef = useRef<ElementRef<typeof DeletePrompt>>(null);

  const fetchMyCompanyProducts = useInfinite({
    name: ['myCompany', 'products'],
    url: 'products/mine',
    enabled: true
  });

  const onDeleteProduct = useCallback((product) => {
    if (deleteModalRef?.current?.open) deleteModalRef?.current?.open(product);
  }, []);

  return (
    <div className="fade-in">
      <div className="w-full flex justify-end">
        <div className="w-full md:w-1/2 lg:w-1/2 flex-center mt-4 ">
          {isAdminView && (
            <Link
              to={{
                pathname: '/panel/product/new',
                state: {company: companyData}
              }}
              className="bg-blue-500 rounded-full px-6 py-2 text-white cursor-pointer flex-center shadow-md text-sm">
              Add Product/Service
              <MdAdd size={24} />
            </Link>
          )}
        </div>
      </div>
      <FetchContainer
        isLoading={fetchMyCompanyProducts.isFetching && !fetchMyCompanyProducts.isFetchingNextPage}
        onReFetch={fetchMyCompanyProducts.refetch}
        isError={fetchMyCompanyProducts.isError}>
        {map(fetchMyCompanyProducts?.data, (product: any, index: number) => (
          <EditableCompanyCard
            key={index}
            product={product}
            totalProductLength={fetchMyCompanyProducts?.data?.length}
            onDelete={onDeleteProduct}
            company={companyData}
            isAdminView={isAdminView}
          />
        ))}
        <DeletePrompt
          ref={deleteModalRef}
          url="products/{id}"
          refetchQueries={[['myCompany', 'products']]}
          title="Are you sure you want to delete {name} ?"
        />
        <ListFooter
          onFetchMoreClick={fetchMyCompanyProducts?.fetchNextPage}
          canFetchMore={fetchMyCompanyProducts?.hasNextPage}
          isFetchingMore={fetchMyCompanyProducts?.isFetchingNextPage}
        />
      </FetchContainer>
    </div>
  );
};
export default EditableProductsTab;
