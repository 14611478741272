import React, {ElementRef, FC, useCallback, useMemo, useRef} from 'react';
import {ListFooter, ServerError, Spinner} from 'components';
import {useInfinite} from 'hooks';
import {DeleteItemModalInfo, MessageModalInfo} from 'types/common';
import {CompanyCard, MessageCard, ProductCard, SendMessageModal, DeleteItemModal} from 'containers';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import type {AdminTabFetchConfig} from 'types/company';

interface Props {
  type: 'company' | 'product' | 'message' | 'comment';
}

const AdminTabs: FC<Props> = ({type}) => {
  const deleteItemRef = useRef<ElementRef<typeof DeleteItemModal>>(null);
  const sendMessageModalRef = useRef<ElementRef<typeof SendMessageModal>>(null);

  const config: AdminTabFetchConfig = useMemo(() => {
    switch (type) {
      case 'company':
        return {
          name: ['panel', 'companies'],
          url: 'companies/paginate-for-companext'
        };
      case 'message':
      case 'comment':
        return {
          name: ['panel', 'messages'],
          url: 'companext-chat/paginate'
        };
      default:
        return {
          name: ['panel', 'products'],
          url: '/products/paginate-for-companext'
        };
    }
  }, [type]);

  const fetchCompanies = useInfinite({
    name: config?.name,
    url: config?.url,
    enabled: true
  });

  const onDeleteItem = useCallback((info: DeleteItemModalInfo) => {
    if (deleteItemRef?.current?.open) deleteItemRef.current.open(info);
  }, []);

  const onSendMessage = useCallback((info: MessageModalInfo) => {
    if (sendMessageModalRef?.current?.open) sendMessageModalRef.current.open(info);
  }, []);

  const ComponentTab = useMemo(() => {
    switch (type) {
      case 'company':
        return CompanyCard;
      case 'message':
      case 'comment':
        return MessageCard;
      default:
        return ProductCard;
    }
  }, [type]);

  const searchHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    debounce(() => {
      fetchCompanies.remove();
      fetchCompanies.fetch({}, {}, {search: e.target.value});
    }, 2000)();
  }, []);

  if (fetchCompanies?.isError) return <ServerError className="mt-16" onTryAgain={fetchCompanies?.refetch} />;
  return (
    <>
      <div className="fade-in">
        <input
          className="border my-6 h-12 rounded-full w-full h-8 px-4 py-4 text-sm focus:border-primary transition duration-200"
          placeholder="Search Company ..."
          onChange={searchHandler}
        />
        {fetchCompanies?.isLoading && !fetchCompanies?.isFetchingNextPage ? (
          <Spinner center className="mt-16" />
        ) : (
          map(fetchCompanies?.data, (company, index: number) => (
            <ComponentTab
              data={company}
              key={index}
              onSendMessageRequested={onSendMessage}
              onDeleteRequested={onDeleteItem}
            />
          ))
        )}
        <ListFooter
          onFetchMoreClick={fetchCompanies?.fetchNextPage}
          canFetchMore={fetchCompanies?.hasNextPage}
          isFetchingMore={fetchCompanies?.isFetchingNextPage}
        />
      </div>
      <SendMessageModal ref={sendMessageModalRef} />
      <DeleteItemModal ref={deleteItemRef} />
    </>
  );
};

export default AdminTabs;
