import React, {FC} from 'react';
import {ProductDataApi} from 'types/product';
import {ProductComment, Spinner, TextBox, TextButton} from 'components';
import {usePost, useUser} from 'hooks';
import {Link, useParams} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import map from 'lodash/map';
import toString from 'lodash/toString';

interface Props {
  data?: ProductDataApi;
  onSuccess?(): void;
}

const schema = yup.object().shape({
  message: yup.string().required('Please insert reply text')
});

const ProductComments: FC<Props> = ({data, onSuccess = () => {}}) => {
  const user = useUser();
  const location = useLocation();
  const {id} = useParams<{id: string | undefined}>();

  const {handleSubmit, control, setValue} = useForm<{message: string}>({
    resolver: yupResolver(schema)
  });

  const postMessage = usePost({
    url: 'comments',
    refetchQueries: [['product', toString(data?.id)]],
    onSuccess: () => {
      if (onSuccess) onSuccess();
      setValue('message', '');
    }
  });

  const onSubmit = ({message}: {message: string}) => {
    postMessage.post({message, product_id: data?.id || id});
  };

  return (
    <form className="mt-8" id="comments" onSubmit={handleSubmit(onSubmit)}>
      <div className="my-4 flex flex-row items-center w-full">
        <TextBox
          control={control}
          name="message"
          placeholder="Have a question about this product/service ? Ask or Comment!"
          containerClassName="w-full"
          className="w-full"
          disabled={!user.isLogin}
        />
        {user?.isLogin && (
          <div className="w-20 mb-auto mt-1">
            <TextButton type="submit" className="fade-in w-full text-black" isLoading={postMessage?.isLoading}>
              Send
            </TextButton>
          </div>
        )}
      </div>
      {!user.isLogin && (
        <p className="text-xs my-4 text-gray-600">
          To add comments, Please
          <Link to="/auth/login" className="text-green-600 ml-1 font-bold">
            Sign In
          </Link>
        </p>
      )}
      <p className="mt-8 text-sm text-gray-800">Previous Questions, Replies and Comments</p>
      {map(data?.comments, (comment) => (
        <ProductComment comment={comment} key={comment?.id} product={data} />
      ))}
    </form>
  );
};
export default ProductComments;
