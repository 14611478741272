import React, {FC, useState} from 'react';
import {ProductDataApi} from 'types/product';
// eslint-disable-next-line import/no-unresolved
import {Swiper, SwiperSlide} from 'swiper/react';
import {PageIndicator} from 'components';
import {Link} from 'react-router-dom';

interface Props {
  data?: ProductDataApi;
}

const ProductHeader: FC<Props> = ({data}) => {
  const [activePage, setActivePage] = useState(0);

  return (
    <div className="fade-in">
      <Link
        to={`/${data?.company?.link}`}
        className="bg-gray-600 h-16 w-100 flex items-center py-2 px-8 cursor-pointer">
        <img src={data?.company?.avatar} alt="" className="rounded-full border w-12 h-12" />
        <p className="text-white text-base md:text-md text-lg px-4 lg:px-8 grow">{data?.company?.name}</p>
      </Link>
      <div className="w-100 image-Container relative">
        <div className=" w-full mt-6 absolute z-10 ">
          <PageIndicator count={data?.pictures?.length || 0} activeIndex={activePage} />
        </div>
        <div className="absolute z-10 bottom-0 m-auto w-full text-center" style={{bottom: 20}}>
          <p className="text-white text-2xl text-shadow-white font-bold dir-rtl">{data?.name}</p>
        </div>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={(e) => setActivePage(e?.realIndex)}
          className="h-full min-h-[40vh]">
          {data?.pictures?.map((picture) => (
            <SwiperSlide>
              <div className="w-full h-full bg-gray-200 select-none" key={picture?.id}>
                <img src={picture?.path_url} alt="product " className="w-full h-full object-cover" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default ProductHeader;
