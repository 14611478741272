import React from 'react';
import {useFetch} from 'hooks';
import {TextBox} from 'components';
import {SocialNetwork} from 'types/company';
import map from 'lodash/map';
import find from 'lodash/find';
import type {Control} from 'react-hook-form';
import replace from 'lodash/replace';

interface Props {
  control: Control<any>;
  data: SocialNetwork[];
}

const EditableSocialMedia: React.FC<Props> = ({control, data}) => {
  const fetchSocialNetworks = useFetch({
    name: 'socialNetworks',
    url: 'social_networks',
    enabled: true
  });

  return (
    <div className="flex my-4 ">
      <div className="flex-3 flex h-full">
        <p className="text-black text-sm font-bold mt-2">Social Media</p>
      </div>
      <div className="flex-7">
        {map(fetchSocialNetworks?.data, (item: any) => (
          <div className="flex flex-col sm:flex-row my-2 items-center w-full" key={item?.id}>
            <p className="text-sm text-gray-600 mb-2 mr-auto sm:mx-2 sm:mb-0">
              {replace(item?.link, /https:\/\/|www./g, '')}
            </p>
            <TextBox
              name={`social_networks.${item?.id}.link`}
              control={control}
              defaultValue={find(data, ['id', item?.id])?.company_link || undefined}
              containerClassName="w-full"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default EditableSocialMedia;
