import {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {FilterContext} from 'contexts';
import {useInfinite} from 'hooks';
import debounce from 'lodash/debounce';
import type {FilterItem} from 'types/company';

function useFilter() {
  const history = useHistory();
  const {filters, setFilters} = useContext(FilterContext);

  const fetchCompanies = useInfinite({
    name: 'companies',
    url: 'companies/paginate',
    enabled: false
  });

  const fetchProducts = useInfinite({
    name: 'products',
    url: 'products/paginate',
    enabled: false
  });

  const addFilter = (newFilter: object) => {
    fetchCompanies.fetch(undefined, {...(filters || {}), ...newFilter, country: undefined});
    fetchProducts.fetch(undefined, {...(filters || {}), ...newFilter, country: undefined});
    setFilters((prevState) => ({...(prevState || {}), ...newFilter}));
  };

  const debounceFilter = useCallback(
    debounce((newFilter: FilterItem) => {
      fetchCompanies.fetch(undefined, {...newFilter, country: undefined});
      fetchProducts.fetch(undefined, {...newFilter, country: undefined});
      if (history.location.pathname !== '/') history.push('/');
    }, 500),
    []
  );

  const onSearch = (search: string) => {
    setFilters((prevState) => ({...(prevState || {}), ...{search: search?.length ? search : undefined}}));
    debounceFilter({...(filters || {}), ...{search: search?.length ? search : undefined}});
  };

  const clearFilter = () => {
    setFilters(undefined);
  };

  return {
    filters,
    setFilters,
    onSearch,
    clearFilter,
    addFilter
  };
}

export default useFilter;
