import React, {useState, SetStateAction, Dispatch, useEffect, FC, createContext, useMemo} from 'react';
import {userAccessProps} from 'types/auth';
import isEqual from 'lodash/isEqual';
import {saveToCookie, getFromCookie} from 'utils';

interface IUserCtx {
  user: userAccessProps;
  setUser: Dispatch<SetStateAction<userAccessProps>>;
}

const UserContext = createContext<IUserCtx>(null as any);

export const UserProvider: FC = ({children}) => {
  const initialUser: userAccessProps = {
    isLogin: false
  };
  let initialState: userAccessProps = initialUser;
  try {
    initialState = getFromCookie('token') != false ? getFromCookie('token') : initialUser;
  } catch {
    initialState = initialUser;
  }

  const [user, setUser] = useState(initialState);

  const userProviderValue = useMemo(() => ({user, setUser}), [user]);

  useEffect(() => {
    const persistToken = getFromCookie('token');
    if (!isEqual(persistToken, user)) {
      saveToCookie('token', user, {
        secure: true,
        expires: 30,
        sameSite: 'none'
      });
    }
  }, [user]);

  return <UserContext.Provider value={userProviderValue}>{children}</UserContext.Provider>;
};

export default UserContext;
