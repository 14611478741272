import React, {FC} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Home, Company, Product, Static, NotFound} from 'pages';

const HomeRoutes: FC = () => {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/product/:id" component={Product} exact />
      <Route path="/static/:key" component={Static} />
      <Route path="/:id" component={Company} exact />
      <Route component={NotFound} />
    </Switch>
  );
};

export default HomeRoutes;
