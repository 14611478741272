import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import {Button, Modal, TextArea} from 'components';
import {usePost} from 'hooks';
import {MdDelete} from 'react-icons/md';
import {useForm} from 'react-hook-form';
import {allocateParamToString} from 'utils';
import isEmpty from 'lodash/isEmpty';
import type {DeleteItemModalInfo} from 'types/common';

interface refProps {
  open: (deleteInfo: DeleteItemModalInfo) => void;
  close: () => void;
}

interface Props {
  ref: RefObject<refProps>;
}

const DeleteItemModal: ForwardRefRenderFunction<refProps, Props> = ({ref}, forwardedRef: ForwardedRef<refProps>) => {
  const [deleteInfo, setDeleteInfo] = useState<DeleteItemModalInfo | undefined>(undefined);

  useImperativeHandle(forwardedRef, () => ({
    open(deleteInfo: DeleteItemModalInfo) {
      setDeleteInfo(deleteInfo);
    },
    close() {
      setDeleteInfo(undefined);
    }
  }));

  const {control, handleSubmit} = useForm();

  const postMessage = usePost({
    url: 'send-message'
  });

  const postDelete = usePost({
    url: '{url}/{id}',
    method: 'DELETE',
    successMessage: 'Item Was Deleted Successfully.'
  });

  const onSubmit = (data: any) => {
    if (deleteInfo?.hasComment)
      postMessage.post({
        message: data?.message,
        company_id: deleteInfo?.url === 'companies' ? deleteInfo?.id : undefined,
        product_id: deleteInfo?.url === 'products' ? deleteInfo?.id : undefined
      });
    postDelete.post({}, {}, deleteInfo);
  };

  const onClose = useCallback(() => setDeleteInfo(undefined), []);

  return (
    <Modal isOpen={!isEmpty(deleteInfo)} onClose={onClose}>
      <form className="bg-white w-1/2 rounded shadow-xl p-8" onSubmit={handleSubmit(onSubmit)}>
        {deleteInfo?.hasComment ? (
          <TextArea name="message" control={control} placeholder="Write Your Comment ..." />
        ) : (
          <p className="my-4">{allocateParamToString('Are You Sure You Want To Delete {name} ?', deleteInfo)}</p>
        )}
        <div className="flex w-full justify-end my-4">
          <Button className="border-gray-500 text-gray-500 mx-2 min-w-[5rem]" type="button" small onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="border-red-500 text-red-500 flex items-center"
            small
            type="submit"
            icon={<MdDelete size={20} className="mx" />}
            isLoading={postDelete?.isLoading || postMessage.isLoading}>
            Yes,Delete
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default forwardRef(DeleteItemModal);
