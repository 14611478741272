import React, {ElementRef, FC, useCallback, useRef} from 'react';
import {Button} from 'components';
import {SelectCountry} from 'containers';
import {IoMdArrowDropdown} from 'react-icons/io';
import {useFilter} from 'hooks';
import clsx from 'clsx';

const TopSearchBox: FC = () => {
  const selectCountryRef = useRef<ElementRef<typeof SelectCountry>>(null);
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const {filters, onSearch} = useFilter();

  const onOpenSelectCountry = useCallback(() => {
    if (selectCountryRef?.current?.open) selectCountryRef.current.open();
  }, []);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchBoxRef.current && window.navigator.userAgent?.toLowerCase()?.includes('mobi')) {
      const viewportOffset = searchBoxRef?.current.getBoundingClientRect();
      if (viewportOffset?.top > 100) window.scrollTo({top: viewportOffset?.top - 30, behavior: 'smooth'});
    }
    onSearch(e.target.value);
  };

  return (
    <div className="w-full flex items-center duration-300 flex-row rounded-full !outline-none shadow-[0_0_3px] shadow-slate-300 focus-within:shadow-primary">
      <input
        ref={searchBoxRef}
        placeholder="Search Company, Product or Service ..."
        className="!border-none !outline-none grow px-4 bg-transparent w-0"
        onChange={onChangeSearch}
        value={filters?.search || ''}
        type="text"
      />
      {!!filters?.search && (
        <Button className="border-0" onClick={() => onSearch('')}>
          X
        </Button>
      )}
      <Button
        className={clsx('h-full outline-none lg:border-t xl:border-t border-slate-300 lg:min-w-[16%] min-w-[25%]', {
          'bg-slate-300 text-gray-600': !filters?.country?.name,
          '!border-primary bg-primary': !!filters?.country?.name
        })}
        fill={!!filters?.country?.name}
        onClick={onOpenSelectCountry}>
        <div className="flex justify-center items-center h-full text-xs md:text-base">
          <IoMdArrowDropdown size={20} className={clsx({'text-gray-600': filters?.country?.name})} />
          <span>{filters?.country?.name || 'Country'}</span>
        </div>
      </Button>
      <SelectCountry ref={selectCountryRef} />
    </div>
  );
};
export default TopSearchBox;
