import React, {FC, useCallback} from 'react';
import {LinkButton} from 'components';
import {UserMenuTop, UserProfileButton} from 'containers';
import {useFilter, useUser} from 'hooks';
import {logoMiniImg} from 'assets';
import {reloadWithRefreshCache} from 'utils';

const Header: FC = () => {
  const user = useUser();
  const {filters, onSearch} = useFilter();

  const searchHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  }, []);

  return (
    <header className="w-100 px-2 md:px-4 lg:px-16 xl:px-20 flex justify-between border-b-2 border-primary ">
      <div className="flex flex-row items-center">
        <div className="w-10" onClick={reloadWithRefreshCache}>
          <img src={logoMiniImg} alt="companext logo" className="w-10 py-4 mx-2 cursor-pointer" />
        </div>
        <input
          value={filters?.search || ''}
          placeholder="Search ..."
          className="border-slate-300 border rounded-full w-full h-8 px-4 py-4 text-sm focus:border-primary transition duration-200 w-48 hidden md:flex ml-4 px-1"
          onChange={searchHandler}
        />
      </div>
      <div className="flex w-full justify-end items-center">
        <UserMenuTop isLoggedIn={user?.isLogin} />
        {user?.isLogin ? (
          <UserProfileButton />
        ) : (
          <>
            <LinkButton to="/auth/register" small className="mx-2 text-secondary" link>
              Join Now
            </LinkButton>
            <LinkButton to="/auth/login" small primary className="text-sm">
              Sign In
            </LinkButton>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
