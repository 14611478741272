import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useImperativeHandle,
  useState
} from 'react';
import {Modal, FetchContainer} from 'components';
import {useFetch, useFilter} from 'hooks';
import clsx from 'clsx';
import map from 'lodash/map';
import concat from 'lodash/concat';
import type {CountryItem} from 'types/common';

const allCountries = {
  id: undefined,
  name: 'All Countries'
};

interface refProps {
  open: () => void;
  close: () => void;
}

interface Props {
  ref?: RefObject<refProps>;
}

const SelectCountry: ForwardRefRenderFunction<refProps, Props> = ({ref}, forwardedRef: ForwardedRef<refProps>) => {
  const [open, setOpen] = useState<boolean>(false);
  const {filters, addFilter} = useFilter();

  useImperativeHandle(forwardedRef, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    }
  }));

  const fetchCountries = useFetch({
    name: 'selectedCountries',
    url: 'selected-countries',
    enabled: true
  });

  const onClose = () => setOpen(false);

  const filterCountry = (country: CountryItem) => {
    addFilter({country, country_id: country?.id});
    onClose();
  };

  return (
    <Modal isOpen={open} onClose={onClose} modalClassName="lg:w-1/4 xl:w-1/4">
      <div>
        <p className="text-sm text-gray-400 text-center">Select Country</p>
        <FetchContainer
          onReFetch={fetchCountries?.refetch}
          isLoading={fetchCountries?.isFetching}
          isError={fetchCountries?.isError}>
          <ul>
            {map(concat(allCountries, fetchCountries?.data), (country) => (
              <li className="cursor-pointer" onClick={() => filterCountry(country)}>
                <p
                  className={clsx(
                    'hover:text-primary p-2',
                    {'text-primary': country?.name === filters?.country?.name},
                    {'text-gray-900': country?.name !== filters?.country?.name}
                  )}>
                  {country?.name}
                </p>
              </li>
            ))}
          </ul>
        </FetchContainer>
      </div>
    </Modal>
  );
};

export default forwardRef(SelectCountry);
