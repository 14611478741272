import React from 'react';
import {DescriptionItem} from 'containers';
import map from 'lodash/map';
import {CompanyDataApi} from 'types/company';

interface Props {
  data?: CompanyDataApi;
}

const AboutTab: React.FC<Props> = ({data}) => {
  return (
    <div className="fade-in">
      <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line my-4">
        {data?.description}
      </p>
      <DescriptionItem title="Contact" content={data?.contact} />
      <DescriptionItem title="Website" content={data?.website} isLink />
      <DescriptionItem title="Industry" content={data?.industries.map((item) => item.name).join(', ')} />
      <DescriptionItem title="Company Type" content={data?.types.map((item) => item.name).join(', ')} />
      <DescriptionItem title="Selected Customers" content={data?.customers} />
      <DescriptionItem title="Certificates" content={data?.certificates} />
      <DescriptionItem title="Social Media" hide={Number(data?.social_networks?.length) < 1}>
        {map(data?.social_networks, (item) => (
          <p>
            <a href={`${item?.link}${item?.company_link}`} target="_blank" rel="noopener noreferrer">
              {item?.name}
            </a>
          </p>
        ))}
      </DescriptionItem>
      <div>
        <p className={`text-black ${Number(data?.gallery?.length) < 1 && 'hidden'}`}>Gallery</p>
        {data?.gallery?.map((item) => (
          <div className="w-full border rounded-lg my-4">
            <img src={item.path_url} alt="gallery" className="w-full h-auto rounded-lg" />
          </div>
        ))}
      </div>
    </div>
  );
};
export default AboutTab;
