import React, {FC, useEffect, useState} from 'react';
// eslint-disable-next-line import/no-unresolved
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper';
import {PageIndicator, Spinner, ImageUploadInput} from 'components';
import {Link} from 'react-router-dom';
import {Control, useController} from 'react-hook-form';
import map from 'lodash/map';
import {convertImageUrlToPath} from 'utils';
import type {CompanyDataApi} from 'types/company';
import type {ProductDataApi} from 'types/product';

interface Props {
  product?: ProductDataApi;
  company?: CompanyDataApi;
  control: Control<any>;
  defaultImages?: any[];
}

const EditableProductHeader: FC<Props> = ({product, company, defaultImages = [], control}) => {
  const [activePage, setActivePage] = useState<number>(0);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);

  const {
    field: {onChange, value}
  } = useController({
    name: 'pictures',
    control,
    defaultValue: map(defaultImages, (image: any) => ({
      path: convertImageUrlToPath(image?.path_url),
      url: image?.path_url
    }))
  });

  useEffect(() => {
    setActivePage(0);
  }, [defaultImages]);

  return (
    <div className="">
      <Link to={`/${company?.name}`} className="bg-zinc-500 h-16 w-100 flex cursor-pointer items-center py-2 px-8">
        <div>
          <img src={company?.avatar} alt="company avatar" className="rounded-full border w-12 h-12" />
        </div>
        <p className="text-white text-lg px-4">{company?.name}</p>
      </Link>
      {isLoadingUpload && <Spinner center className="my-4" />}
      {value && value?.length > 0 && (
        <div className="w-100 relative">
          {!isLoadingUpload && (
            <div className=" w-full mt-6 absolute z-10 ">
              <PageIndicator count={value?.length || 0} activeIndex={activePage} />
            </div>
          )}
          <div className="absolute z-10 bottom-0 m-auto w-full text-center" style={{bottom: 20}}>
            <p className="text-white text-2xl text-shadow-white font-bold">{product?.name}</p>
          </div>
          {!isLoadingUpload && (
            <Swiper
              pagination
              modules={[Pagination]}
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={(e) => setActivePage(e.realIndex)}
              className="h-full">
              {map(value, (picture: any, index: number) => (
                <SwiperSlide key={index}>
                  <div className="w-full h-full bg-gray-200 select-none">
                    {picture && (
                      <img
                        src={picture.file ? URL.createObjectURL(picture.file) : picture.url}
                        alt="product"
                        className="w-full h-full select-none"
                      />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      )}
      <ImageUploadInput
        onUploadImage={(newImage) => {
          onChange([newImage]);
        }}
        hasImage={!!value?.length}
        changeUploadingStatus={setIsLoadingUpload}
      />
    </div>
  );
};
export default EditableProductHeader;
