import {AxiosInstance} from 'libs';
import axios, {AxiosRequestConfig} from 'axios';
import {toast} from 'react-toastify';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import qs from 'qs';
import {useUser} from 'hooks';
import {urlGenerator, getFromCookie} from 'utils';
import {useHistory} from 'react-router-dom';
import merge from 'lodash/merge';
import set from 'lodash/set';
import get from 'lodash/get';
import type {userAccessProps} from 'types/auth';

const useAxios = () => {
  const user = useUser();
  const history = useHistory();

  const refreshAuthLogic = (failedRequest: any) => {
    const requestConfig: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 15000,
      url: urlGenerator('auth/refresh', undefined),
      method: 'POST',
      headers: {Authorization: `Bearer ${user?.token}`, silent: true},
      data: {refresh_token: user?.token}
    };

    return axios(requestConfig)
      .then((tokenRefreshResponse) => {
        const newUsers: userAccessProps = getFromCookie('token');
        set(newUsers, 0, merge({...get(newUsers, 0), is_logged_in: true}, tokenRefreshResponse?.data?.data));
        user.setUser(newUsers);
        failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data?.data?.access_token}`;
        return Promise.resolve();
      })
      .catch(() => {
        const currentUsers = getFromCookie('token');
        currentUsers.shift();
        user.setUser(currentUsers);
        history.replace({
          pathname: '/',
          search: qs.stringify({redirect: history.location?.pathname})
        });
        // if request is post or patch (not get) show notification to user resend request
        if (failedRequest.response.config?.method !== 'get') {
          toast.warn('please try again');
        }
      });
  };

  createAuthRefreshInterceptor(AxiosInstance, refreshAuthLogic, {statusCodes: [401]});

  return AxiosInstance;
};

export default useAxios;
