import React, {FC, Suspense} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import ScrollRestoration from 'react-scroll-restoration';
import {Spinner} from 'components';
import {PageContainer, Connection} from 'containers';
import {useUser} from 'hooks';
import AuthRoutes from 'routes/auth';
import PanelRoutes from 'routes/panel';
import HomeRoutes from 'routes/home';

const Routes: FC = () => {
  const user = useUser();

  return (
    <BrowserRouter>
      <ScrollRestoration />
      <PageContainer>
        <Connection>
          <Suspense
            fallback={
              <div className="flex flex-center" style={{height: '70vh'}}>
                <Spinner center className="w-full" />
              </div>
            }>
            <Switch>
              {!user?.isLogin ? (
                <Route path="/auth" component={AuthRoutes} />
              ) : (
                <Route path="/panel" component={PanelRoutes} />
              )}
              <Route path="/" component={HomeRoutes} />
            </Switch>
          </Suspense>
        </Connection>
      </PageContainer>
    </BrowserRouter>
  );
};

export default Routes;
