import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import * as yup from 'yup';
import {TextBox, Button} from 'components';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {usePost, useUser} from 'hooks';
import {toast} from 'react-toastify';
import type {NewPasswordProps} from 'types/auth';

const schema = yup.object().shape({
  password: yup.string().required(),
  repeat_password: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords dont match')
    .required('repeat password is required')
});

const ChangeOldPasswordForm: FC = () => {
  const history = useHistory();
  const user = useUser();
  const {control, handleSubmit} = useForm<NewPasswordProps>({
    resolver: yupResolver(schema)
  });

  const changePassword = usePost({
    url: 'change_password',
    onSuccess() {
      toast.success('Password Successfully changed');
      history.push('/');
    }
  });

  const submit = (data: NewPasswordProps) => {
    changePassword.post(data);
  };

  return (
    <form className="fade-in" onSubmit={handleSubmit(submit)}>
      <div className="flex-center flex-col mt-24">
        <p className="text-center text-xl bg-gray-300 mb-8">{user?.email}</p>
        <div className="md:w-3/4 lg:w-2/5">
          <TextBox
            placeholder="New Password"
            containerClassName="w-full mb-4 mt-8"
            name="password"
            type="password"
            control={control}
          />
          <TextBox
            placeholder="Repeat New Password"
            containerClassName="w-full mb-4"
            name="repeat_password"
            type="password"
            control={control}
          />
          <Button fill className="w-full" type="submit" isLoading={changePassword.isLoading}>
            Save Changes
          </Button>
        </div>
      </div>
    </form>
  );
};
export default ChangeOldPasswordForm;
